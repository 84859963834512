/* eslint-disable n/prefer-global/buffer */
// eslint-disable-next-line unicorn/prefer-node-protocol
import { Buffer } from "buffer";
import RSK from "@riseworks.io/rsk";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/html";
import {
  GetWalletClientResult,
  configureChains,
  connect,
  createConfig,
  disconnect,
  getWalletClient,
  switchNetwork,
} from "@wagmi/core";
import { arbitrum } from "@wagmi/core/chains";
import { MetaMaskConnector } from "@wagmi/connectors/metaMask";
import { CoinbaseWalletConnector } from "@wagmi/connectors/coinbaseWallet";

if (!window.Buffer) {
  window.Buffer = Buffer;
}

const chains = [arbitrum];
const projectId = "1a013d19cadf19f0e6f40810f9b81a8c";

const mmConnector = new MetaMaskConnector({ chains });
const cbConnector = new CoinbaseWalletConnector({
  chains,
  options: {
    appName: "Riseworks",
  },
});
const rskSdk = RSK();

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiClient = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});

const connectors = {
  metamask: mmConnector,
  coinbase: cbConnector,
};

const ethereumClient = new EthereumClient(wagmiClient, chains);
const web3modal = new Web3Modal(
  {
    projectId,
    explorerRecommendedWalletIds: [
      "3fecad5e2f0a30aba97edea69ebf015884a9b8a9aec93e66d4b4b695fee1f010",
      "f2436c67184f158d1beda5df53298ee84abfc367581e4505134b5bcf5f46697d",
    ],
    explorerExcludedWalletIds: "ALL",
  },
  ethereumClient
);
web3modal.setDefaultChain(arbitrum);

let walletClient: GetWalletClientResult | null = null;

export function useWeb3() {
  return {
    async connect(type: string, _email?: string) {
      walletClient = await getWalletClient();
      if (walletClient) {
        await disconnect();
        walletClient = null;
      }
      if (type === "rsk") {
        await rskSdk.connect();
      } else if (["metamask", "coinbase"].includes(type)) {
        try {
          await connect({
            chainId: arbitrum.id,
            connector: connectors[type as keyof typeof connectors],
          });
        } catch (error) {
          // Handle the "Unrecognized chain ID" error
          if (error instanceof Error) {
            // Add the arbitrum Sepolia chain to the wallet first
            await window.ethereum?.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: `0x${arbitrum.id.toString(16)}`,
                  chainName: arbitrum.name,
                  nativeCurrency: arbitrum.nativeCurrency,
                  rpcUrls: [arbitrum.rpcUrls.default.http[0]],
                  blockExplorerUrls: [arbitrum.blockExplorers.default.url[0]],
                },
              ],
            });
            // Try connecting again
            await connect({
              chainId: arbitrum.id,
              connector: connectors[type as keyof typeof connectors],
            });
          } else {
            throw error;
          }
        }
        walletClient = await getWalletClient({ chainId: arbitrum.id });
      } else if (type === "walletconnect") {
        await new Promise((resolve, _reject) => {
          web3modal.openModal().catch((e) => console.log(e));
          const unsubscribe = web3modal.subscribeModal(async (v: any) => {
            if (v.open) return;
            walletClient = await getWalletClient();
            resolve(true);
            unsubscribe();
          });
        });
      }
      window.postMessage({ type: "connect-response" }, "*");
    },
    disconnect() {
      walletClient = null;
      return disconnect();
    },
    async getAddress() {
      if (await rskSdk.getAddress()) {
        return rskSdk.getAddress();
      }
      walletClient = await getWalletClient({ chainId: arbitrum.id });
      if (!walletClient) {
        return null;
      }
      return walletClient.account.address;
    },
    async signTypedData(params: any) {
      if (await rskSdk.getAddress()) {
        return rskSdk.signTypedData({
          domain: params.domain,
          types: params.types,
          message: params.typed_data,
        });
      }
      walletClient = await getWalletClient({ chainId: arbitrum.id });
      switchNetwork({ chainId: arbitrum.id });
      if (!walletClient) {
        throw new Error("Wallet not connected");
      }

      return walletClient.signTypedData({
        account: walletClient.account,
        domain: params.domain,
        primaryType: params.primary_type,
        types: params.types,
        message: params.typed_data,
      });
    },
    async signMessage(message: string) {
      if (await rskSdk.getAddress()) {
        return rskSdk.signMessage(message);
      }
      walletClient = await getWalletClient({ chainId: arbitrum.id });
      if (!walletClient) {
        throw new Error("Wallet not connected");
      }
      return walletClient.signMessage({ message });
    },
  };
}
